import React, {useState, useEffect} from "react";
import {Helmet} from "react-helmet";

const Home = ({data}) => {
  const [isMobile, setIsMobile] = useState(false);
  function handleWindowSizeChange() {
    setIsMobile(window.innerWidth <= 768);
  }
  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <section
        id="home"
        className="container-fluid home-bg"
        style={{backgroundImage: `url(${data.image})`}}
      >
        <article className="row align-items-center" style={{height: "100%"}}>
          <div
            className={`col-lg-10 ${isMobile ? "px-0 text-center" : "px-5"} ${
              data.bgBlack ? "bg-black-transparent" : ""
            }`}
            style={{
              marginTop: "10rem",
              paddingTop: "1rem",
              width: `${isMobile ? "90%" : "60%"}`,
              marginLeft: "5%",
              paddingRight: "1rem !important",
            }}
          >
            <h1
              className={`${
                isMobile ? "fadeInLeft" : "fadeInUp"
              } home-heading display-5 mb-1 pb-5 animated  text-light font-opensans`}
            >
              {data.heading}
            </h1>
            <p
              className={`lead mb-1 pb-4 animated  text-light ls-1 ${
                isMobile ? "lead-small fadeInLeft" : "fadeInUp"
              }`}
            >
              {data.description}
            </p>
            {data.readMoreButton && (
              <a href="" className="btn btn-primary py-3animated fadeInLeft">
                Explore More
              </a>
            )}
          </div>
          <div className="mt-auto text-center pb-5">
            <a
              href="#services"
              className="btn btn-lg text-light bounce"
              style={{fontSize: "2em"}}
              aria-label="Continue reading about softaim technologies"
            >
              <i className="bi bi-chevron-down"></i>
            </a>
          </div>
        </article>
      </section>
    </>
  );
};

export default Home;
