import React from "react";
import Home from "../Home";
import TransportationImg from "../../img/transport-full.webp";
import TMS from "../../img/icons/manufacturing.png";
import WMS from "../../img/icons/warehouse.png";
import GPS from "../../img/icons/gps.png";
import SCM from "../../img/icons/supply-chain.png";
import {Helmet} from "react-helmet";

const Transportation = () => {
  return (
    <div className="bg-black text-light">
      <Helmet>
        <title>
          Software services for transportation industry by Softaim Technologies
        </title>

        <meta
          name="description"
          content="Softaim Technologies - Transportation Management Systems, Warehouse Management Systems, GPS Tracking and Notifications, Supply Chain Visibility and more."
        />
        <meta
          name="keywords"
          content="softaim technologies,transport business management, transport software, logistic management, logistic software, warehouse management, "
        ></meta>
      </Helmet>
      <Home
        data={{
          heading: "Transportation and Logistics",
          description:
            "Meet customer demands efficiently, reduce costs, enhance supply chain visibility, and adapt to the evolving needs of the global marketplace.",
          image: TransportationImg,
          readMoreButton: false,
          //   bgBlack: true,
        }}
      />
      <section
        id="services"
        className="container-fluid pb-5 p-100 text-light bg-black"
      >
        <div className="container">
          <div className="row g-4 align-items-end pb-4 mt-4">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
              <h2 className="display-5 mb-4 text-center">
                Transportation and logistics industry offerings
              </h2>
              <h5 className="lead text-center">
                Software applications that are vital for the efficient movement
                of goods and services in today's global economy.
              </h5>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={TMS}
                        alt="Transportation Management Systems"
                      ></img>
                    </h5>
                    <h3 className="card-title mb-3 pt-3">
                      Transportation Management Systems
                    </h3>
                    <p className="lead">
                      Optimizes the entire transportation process, from order
                      planning and execution to payment and auditing
                    </p>
                    <ul className="card-text">
                      <li>Route Optimization</li>
                      <li>Carrier Selection</li>
                      <li>ERP Integration</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img src={WMS} alt="Warehouse Management Systems"></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Warehouse Management Systems
                    </h3>
                    <p className="lead">
                      Efficiently organizing, tracking, and controlling the
                      movement and storage of goods within a warehouse or
                      distribution center
                    </p>
                    <ul className="card-text">
                      <li> Inventory Management</li>
                      <li> Serial Number Tracking</li>
                      <li> RFID Integration</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img src={GPS} alt="GPS Tracking and Notifications"></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      GPS Tracking and Notifications
                    </h3>
                    <p className="lead">
                      Monitor, manage, and improve various aspects of fleet
                      operations, including safety, efficiency, and overall
                      performance
                    </p>
                    <ul className="card-text">
                      <li>Real-Time Location</li>
                      <li>
                        Telemetry Data such as vehicle speed, direction, and
                        altitude
                      </li>
                      <li>Notifications about estimated arrival times </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img src={SCM} alt="Supply Chain Visibility"></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Supply Chain Visibility
                    </h3>
                    <p className="lead">
                      Track, monitor, and gain real-time insights into the
                      movement of goods and information throughout the entire
                      supply chain.
                    </p>
                    <ul className="card-text">
                      <li>Real-time data exchange and communication</li>
                      <li>Advanced Analytics and Reporting </li>
                      <li>Customer-Facing Visibility</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Transportation;
