import SoftwareImage from "./img/software.webp";
import WebImage from "./img/web.webp";
import MobileImage from "./img/mobile.webp";
import ERPImage from "./img/erp.webp";
import IIOTImage from "./img/iiot.webp";
import EducationImg from "./img/education.webp";
import FactoryImg from "./img/factory.webp";
import FoodImg from "./img/food.webp";
import HotelImg from "./img/hotel.webp";
import HealthImg from "./img/health.webp";
import IndustryImg from "./img/industry.webp";
import EcommerceImg from "./img/ecommerce.webp";
import TransportImg from "./img/transport.webp";
import CarouselImg from "./img/software-full.webp";
export const homePageData = {
  heading: "Technology Excellence for Sustainable Value Addition",
  description:
    "Adding significant value and driving growth for organizations of all sizes and industries.",
  image: CarouselImg,
  readMoreButton: false,
  bgBlack: false,
};
export const contactDetails = {
  address:
    "49, Ahmad Bagh, Opp. Income Tax Office, Court Road, Saharanpur, India",
  mobile: "+91 9557639938",
  alternateMobile: "+91 8527884232",
  email: "info@softaim.in",
};
export const services = [
  {
    title: "Industrial Internet of Things",
    href: "/services/industrial-iot",
    titleSmall: "Industrial IoT",
    intro: "Data-driven decisions",
    keyPoints: ["Connected Sensors", "Cloud Integration", "Remote Monitoring"],
    description:
      "IIoT connects various industrial assets, such as machines, sensors, and production equipment, to centralized systems and cloud-based platforms. It allows operators and engineers to remotely monitor equipment and processes from anywhere.",
    image: IIOTImage,
  },
  {
    title: "Software Development",
    href: "/services/software-development",
    titleSmall: "",
    intro: "Customised solution for your business needs",
    description:
      "Customized software is an ideal choice when standard software solutions cannot fully address the specific needs of an organization. We offer a tailored, efficient, and effective solution to achieve business objectives.",
    keyPoints: [
      "Tailored to Specific Requirements",
      "Flexibility and Scalability",
      "Integration Capabilities",
    ],
    image: SoftwareImage,
  },
  {
    title: "Web Development",
    href: "/services/web-development",
    titleSmall: "",
    intro: "Your Online Presence in today's digital world",
    description:
      "A website provides a digital presence that allows you to be accessible to a global audience 24/7. It acts as an online storefront or portfolio, showcasing products, services, or information.",
    keyPoints: [
      "Customer Convenience",
      "Global Reach",
      "Competitive Advantage",
    ],
    image: WebImage,
  },
  {
    title: "Mobile Applications",
    href: "/services/mobile-application",
    titleSmall: "",
    intro: "Faster load times and smoother performance",
    keyPoints: [
      "Speed and Performance",
      "Push Notifications",
      "Offline Access",
    ],
    description:
      "It offer a versatile platform for businesses and individuals to enhance user experiences, and leverage the capabilities of modern mobile devices. It's a powerful tool for communication, productivity, entertainment, and commerce.",
    image: MobileImage,
  },
  {
    title: "ERPs and CRMs",
    href: "/services/erp-and-crm",
    titleSmall: "",
    intro: "Tools that offer various benefits to organizations",
    keyPoints: [
      "Enhanced Collaboration",
      "Inventory Management",
      "Improved Customer Retention",
    ],
    description:
      "ERP and CRM systems offer distinct advantages to organizations. When integrated, it leads to increased efficiency, cost savings, improved decision-making, and ultimately, business growth.",
    image: ERPImage,
  },
];

export const industries = [
  {
    title: "Factory Automation",
    href: "/industries/factory-automation",
    image: FactoryImg,
    keyPoints: [
      "Machine Vision Systems",
      "Sensor data to Cloud",
      "Process Control Software",
      "Material Handling Systems",
    ],
  },
  {
    title: "Industry 4.0",
    href: "/industries/industry4",
    image: IndustryImg,
    keyPoints: [
      "Cloud-Based Manufacturing",
      "Smart Sensors",
      "IoT Platforms",
      "Industrial Robots",
    ],
  },
  {
    title: "E-Commerce",
    href: "/industries/ecommerce",
    image: EcommerceImg,
    keyPoints: [
      "Online Storefront",
      "Inventory Management",
      "Live Chat and Helpdesk",
      "User Reviews and Ratings",
    ],
  },
  {
    title: "Education and Institutions",
    href: "/industries/educational-and-institutional",
    image: EducationImg,
    keyPoints: [
      "Student Information System",
      "Assessment and Testing App",
      "Library Management System",
      "Data Analytics and Reporting",
    ],
  },

  {
    title: "Food Industry",
    href: "/industries/food-industry",
    image: FoodImg,
    keyPoints: [
      "Supply Chain Management",
      "Inventory Management Software",
      "Online Food Ordering",
      "Customer Loyalty and CRM",
    ],
  },
  {
    title: "Healthcare",
    href: "/industries/healthcare",
    image: HealthImg,
    keyPoints: [
      "Hospital Information System",
      "Electronic Medical Records",
      "Online Appointment System",
      "Patient Management System",
    ],
  },
  {
    title: "Hospitality and Tourism",
    href: "/industries/hospitality-and-tourism",
    image: HotelImg,
    keyPoints: [
      "Hotel Management System",
      "Online Reservation System",
      "Travel Agency Management",
      "Tour and Activity Booking System",
    ],
  },

  {
    title: "Transportation",
    href: "/industries/transportation",
    image: TransportImg,
    keyPoints: [
      "Transport Management",
      "Warehouse Management",
      "GPS Tracking and Telemetry data",
      "Supply Chain Visibility",
    ],
  },
];
export const features = [
  {
    title: "Customer-Centric Approach",
    description:
      "Regularly seeking feedback and iterating on software based on that feedback.",
  },
  {
    title: "Quality Assurance",
    description:
      "A robust QA process is essential to ensure that software products are reliable, secure, and bug-free.",
  },
  {
    title: "Cost-effectiveness",
    description: "",
  },
  {
    title: "Customer Support",
    description:
      "Providing excellent customer support is key to maintaining positive client relationships.",
  },
];
