import React from "react";
import Home from "../Home";
import Industry4_0Img from "../../img/industry-full.webp";
import ConnectedSensors from "../../img/icons/connected-sensors.png";
import DataAnalytics from "../../img/icons/analytics-and-insights.png";
import CentralizedData from "../../img/icons/centralized-data.png";
import Robotics from "../../img/icons/robotics.png";
import {Helmet} from "react-helmet";

const Industry4_0 = () => {
  return (
    <div className="bg-black text-light">
      <Helmet>
        <title>
          Software services for smart manufacturing industry 4.0 by Softaim
          Technologies
        </title>

        <meta
          name="description"
          content="Softaim Technologies - Smart Sensors and IoT Platforms, Cloud Computing, Data Analytics and Big Data Platforms, Robotics and Automation"
        />
        <meta
          name="keywords"
          content="softaim technologies,iot integration, robot operaing system, Sensor connectivity, sensor communication, Data collection and processing, Real-Time Monitoring of sensor data, sensor data to cloud, sensor data online storage, iot connectivity"
        ></meta>
      </Helmet>
      <Home
        data={{
          heading: "Industry 4.0",
          description:
            "Integration of digital technologies, data analytics, artificial intelligence, and the Internet of Things (IoT) into various aspects of manufacturing and industrial processes",
          image: Industry4_0Img,
          readMoreButton: false,
          //   bgBlack: true,
        }}
      />
      <section
        id="services"
        className="container-fluid pb-5 p-100 text-light bg-black"
      >
        <div className="container">
          <div className="row g-4 align-items-end pb-4 mt-4">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
              <h2 className="display-5 mb-4 text-center">
                Software services for Smart Manufacturing or Industry 4.0
              </h2>
              <h5 className="lead text-center">
                Making manufacturing more efficient, flexible, and responsive to
                customer demands while reducing costs and environmental impact.
              </h5>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={ConnectedSensors}
                        alt="Smart Sensors and IoT Platforms"
                      ></img>
                    </h5>
                    <h3 className="card-title mb-3 pt-3">
                      Smart Sensors and IoT Platforms
                    </h3>
                    <p className="lead">
                      Enabling the collection, transmission, and analysis of
                      data from various devices and machines in the industrial
                      context
                    </p>
                    <ul className="card-text">
                      <li>Sensor connectivity</li>
                      <li>Data collection and processing</li>
                      <li>Real-Time Monitoring</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img src={CentralizedData} alt="Cloud Computing"></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">Cloud Computing</h3>
                    <p className="lead">
                      Infrastructure and services to support the digital
                      transformation of manufacturing and industrial processes.
                    </p>
                    <ul className="card-text">
                      <li> Data storage and management</li>
                      <li> IoT Connectivity</li>
                      <li> Scalability</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={DataAnalytics}
                        alt="Data Analytics and Big Data Platforms"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Data Analytics and Big Data Platforms
                    </h3>
                    <p className="lead">
                      Process and analyze vast amounts of data generated by IoT
                      devices and other sources
                    </p>
                    <ul className="card-text">
                      <li>Operational intelligence dashboards</li>
                      <li>Data-driven decision-making</li>
                      <li>Predictive Maintenance</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img src={Robotics} alt="Robotics and Automation"></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Robotics and Automation
                    </h3>
                    <p className="lead">
                      Enabling industrial robots to perform a wide range of
                      tasks, from precision manufacturing to material handling
                      and assembly
                    </p>
                    <ul className="card-text">
                      <li>Vision Systems and Perception</li>
                      <li>Robot Operating System </li>
                      <li>Connectivity and IoT Integration</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Industry4_0;
