import React from "react";
import Home from "../Home";
import EducationalImg from "../../img/education-full.webp";
import StudentInfo from "../../img/icons/student-information.png";
import DataAnalytics from "../../img/icons/analytics-and-insights.png";
import LMS from "../../img/icons/library.png";
import Exam from "../../img/icons/online-exam.png";
import {Helmet} from "react-helmet";

const Educational = () => {
  return (
    <div className="bg-black text-light">
      <Helmet>
        <title>Educational softwares by Softaim Technologies</title>‍
        <meta
          name="description"
          content="Softaim Technologies - Innovative solutions to meet the diverse needs of students,
                educators, and institutions. Student information system, Library management, assessment and testing application, data analytics for student marks."
        />
        <meta
          name="keywords"
          content="softaim technologies,manage student information, school website, college website, assessment and testing application, library management system, data analytics for student report cards, mobile application development, receive fee online, student fee management"
        ></meta>
      </Helmet>
      <Home
        data={{
          heading: "Education industry",
          description:
            "Transforming the way students learn, teachers instruct, and educational institutions operate",
          image: EducationalImg,
          readMoreButton: false,
          //   bgBlack: true,
        }}
      />
      <section
        id="services"
        className="container-fluid pb-5 p-100 text-light bg-black"
      >
        <div className="container">
          <div className="row g-4 align-items-end pb-4 mt-4">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
              <h2 className="display-5 mb-4 text-center">Education industry</h2>
              <h5 className="lead text-center">
                Innovative solutions to meet the diverse needs of students,
                educators, and institutions while enhancing the overall
                educational experience.
              </h5>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={StudentInfo}
                        alt="student information system"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Student Information System
                    </h3>

                    <ul className="card-text">
                      <li>Personal and contact details</li>
                      <li>Enrollment history</li>
                      <li>Attendance tracking </li>
                      <li>Academic records</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img src={Exam} alt="online examination"></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Assessment and Testing App
                    </h3>

                    <ul className="card-text">
                      <li> Test creation</li>
                      <li> Question banks</li>
                      <li> Scoring and grading</li>
                      <li> Explanations for incorrect responses</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img src={LMS} alt="library management system"></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Library Management System
                    </h3>

                    <ul className="card-text">
                      <li>Cataloging and classification</li>
                      <li>Member registration and borrowing history</li>
                      <li>Circulation management </li>
                      <li>Mobile access </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={DataAnalytics}
                        alt="data analytics and reporting"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Data Analytics and Reporting
                    </h3>

                    <ul className="card-text">
                      <li>Staff performance assessment</li>
                      <li>Student performance analysis </li>
                      <li>Admissions and enrollment analytics</li>
                      <li>Feedback and continuous improvement</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Educational;
