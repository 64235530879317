import React from "react";

const Features = () => {
  return (
    <section
      id="features"
      className="container-fluid feature pb-5 p-100 text-light bg-black"
    >
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">
              Why Choosing Us!
            </p>
            <h2 className="display-5 mb-4">
              Few Reasons Why People Choosing Us!
            </h2>
            <p className="lead mb-4">
              Our highest priority is to satisfy the customer through early and
              continuous delivery of valuable software.
            </p>
            {/* <a className="btn btn-primary py-3 px-5" href="">
              Explore More
            </a> */}
          </div>
          <div className="col-lg-6">
            <div className="row g-4 align-items-center">
              <div className="col-md-6">
                <div className="row g-4">
                  <div className="col-12 wow fadeIn" data-wow-delay="0.25s">
                    <div className="feature-box border rounded p-4">
                      <i className="fa fa-check fa-3x text-primary mb-3 d-none d-sm-block text-info"></i>
                      <h4 className="mb-3 text-info">
                        Client-centric approach
                      </h4>
                      <p className="mb-3 ">
                        Regularly seeking feedback from client and iterating on
                        software based on that feedback.
                      </p>
                      {/* <a className="fw-semi-bold" href="">
                        Read More <i className="fa fa-arrow-right ms-1"></i>
                      </a> */}
                    </div>
                  </div>
                  <div className="col-12 wow fadeIn" data-wow-delay="0.35s">
                    <div className="feature-box border rounded p-4">
                      <i className="fa fa-check fa-3x text-primary mb-3 d-none d-sm-block text-info"></i>
                      <h4 className="mb-3 text-info">
                        Quality Assurance and Testing
                      </h4>
                      <p className="mb-3">
                        We use manual and automated testing to ensure that
                        software products are reliable, secure, and bug-free.
                      </p>
                      {/* <a className="fw-semi-bold" href="">
                        Read More <i className="fa fa-arrow-right ms-1"></i>
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row g-4">
                  <div className="col-12 wow fadeIn" data-wow-delay="0.45s">
                    <div className="feature-box border rounded p-4">
                      <i className="fa fa-check fa-3x text-primary mb-3 d-none d-sm-block text-info"></i>
                      <h4 className="mb-3 text-info">
                        Continuous Learning and Improvement
                      </h4>
                      <p className="mb-3">
                        Our commitment towards learning and improvement keeps us
                        competitive in the rapidly evolving software industry.
                      </p>
                      {/* <a className="fw-semi-bold" href="">
                        Read More <i className="fa fa-arrow-right ms-1"></i>
                      </a> */}
                    </div>
                  </div>
                  <div className="col-12 wow fadeIn" data-wow-delay="0.55s">
                    <div className="feature-box border rounded p-4">
                      <i className="fa fa-check fa-3x text-primary mb-3 d-none d-sm-block text-info"></i>
                      <h4 className="mb-3 text-info">
                        Maintenance and Support
                      </h4>
                      <p className="mb-3">
                        We offer ongoing maintenance, updates, and technical
                        support to ensure software remains secure and
                        up-to-date.
                      </p>
                      {/* <a className="fw-semi-bold" href="">
                        Read More <i className="fa fa-arrow-right ms-1"></i>
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
