import {doc, serverTimestamp, setDoc} from "firebase/firestore";
import db from "./../firebase";

export default class MailAPI {
  // Add/Update Mail
  async setMail(data) {
    console.log("add form data:", data.payload, " to id:", data.id);
    try {
      await setDoc(
        doc(db, "mail", data.id),
        {...data.payload, createdAt: serverTimestamp()},
        {
          merge: true,
        }
      );
      return {success: true};
    } catch (ex) {
      console.log(ex);
      return {success: false, message: ex};
    }
  }
}
