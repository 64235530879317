import React, {useRef, useState, useEffect} from "react";
import MailAPI from "../firebase/firestore";

const Callback = () => {
  const [isMobile, setIsMobile] = useState(false);
  function handleWindowSizeChange() {
    setIsMobile(window.innerWidth <= 468);
  }
  useEffect(() => {
    setIsMobile(window.innerWidth <= 468);
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const nameRef = useRef("");
  const emailRef = useRef("");
  const phoneNumberRef = useRef("");
  const subjectRef = useRef("");
  const messageRef = useRef("");
  const [resMessage, setResMessage] = useState(null);
  const mailAPI = new MailAPI();
  const handleOnSubmit = () => {
    const name = nameRef.current.value;
    const email = emailRef.current.value;
    const phone = phoneNumberRef.current.value;
    const messageSubject = subjectRef.current.value;
    const messageText = messageRef.current.value;
    const messageData =
      "<br/>Name:" +
      name +
      "<br/>Email:" +
      email +
      "<br/>Phone:" +
      phone +
      "<br/>Subject:" +
      messageSubject +
      "<br/>Message:" +
      messageText;
    const data = {
      to: ["info@softaim.in"],
      bcc: ["arpitgoyal138@gmail.com"],
      message: {
        subject: "Contact form submission",
        html: messageData,
      },
    };
    const timestamp = new Date().getTime();
    let ID = "";
    ID = email.trim().toUpperCase();
    ID = ID.substring(0, ID.length);
    ID = ID.replace(/ /g, "_");
    ID += "-" + timestamp;
    // send data to server here...

    const res = mailAPI.setMail({
      id: ID,
      payload: data,
    });
    res
      .then((resData) => {
        console.log(resData);
        if (resData.success) {
          setResMessage({
            status: "SUCCESS",
          });
          clearForm();
        } else {
          setResMessage({
            status: "ERROR",
          });
        }
      })
      .catch((ex) => {
        setResMessage({
          text: "Some error occured. Please try again !!",
          status: "ERROR",
        });
        console.log(ex);
      });
  };
  const clearForm = () => {
    nameRef.current.value = "";
    emailRef.current.value = "";
    phoneNumberRef.current.value = "";
    subjectRef.current.value = "";
    messageRef.current.value = "";
  };
  return (
    <section
      id="contact"
      className="container-fluid callback pb-5 p-100"
      style={{height: "800px"}}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div
              className={`${
                !isMobile ? "border p-5" : ""
              } " bg-black rounded wow fadeInUp"`}
              data-wow-delay="0.1s"
            >
              <div
                className="text-center mx-auto wow fadeInUp"
                data-wow-delay="0.3s"
                style={{maxWidth: "600px"}}
              >
                <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">
                  Let's Collaborate
                </p>
                {resMessage === null && (
                  <h1 className="display-5 mb-5 text-info">
                    Request a Call-Back
                  </h1>
                )}
                {resMessage && resMessage.status === "SUCCESS" && (
                  <div className="alert alert-success" role="alert">
                    Thank you for connecting with us.
                    <br />
                    We will revert back shortly.
                  </div>
                )}
                {resMessage && resMessage.status === "ERROR" && (
                  <div className="alert alert-danger" role="alert">
                    Some error occurred! Please try later.
                  </div>
                )}
              </div>

              <form
                className="row g-3"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleOnSubmit();
                }}
              >
                <div className="col-sm-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      ref={nameRef}
                      required
                    />
                    <label htmlFor="name">Your Name</label>
                    {/* <div className="valid-feedback">Looks good!</div> */}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-floating">
                    <input
                      type="email"
                      className="form-control"
                      id="mail"
                      placeholder="Your Email"
                      ref={emailRef}
                      required
                    />
                    <label htmlFor="mail">Your Email</label>
                    {/* <div className="valid-feedback">Looks good!</div> */}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="mobile"
                      placeholder="Your Mobile"
                      ref={phoneNumberRef}
                    />
                    <label htmlFor="mobile">Your Mobile</label>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="subject"
                      placeholder="Subject"
                      ref={subjectRef}
                      required
                    />
                    <label htmlFor="subject">Subject</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating">
                    <textarea
                      className="form-control"
                      placeholder="Leave a message here"
                      id="message"
                      style={{height: "100px"}}
                      ref={messageRef}
                      required
                    ></textarea>
                    <label htmlFor="message">Message</label>
                  </div>
                </div>
                <div className="col-12 text-center">
                  <button className="btn btn-primary w-100 py-3" type="submit">
                    Submit Now
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Callback;
