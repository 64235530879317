import React, {useState, useEffect} from "react";
import ServicesAccordion from "./ServicesAccordion";
import {services} from "../data";
import {Link} from "react-router-dom";

const Services = () => {
  const [isMobile, setIsMobile] = useState(false);
  function handleWindowSizeChange() {
    setIsMobile(window.innerWidth <= 1040);
  }
  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <section
      id="services"
      className="container-fluid pb-5 p-100 text-light bg-black"
    >
      <div className="container">
        <div
          className="text-center mx-auto wow fadeInUp"
          data-wow-delay="0.1s"
          style={{maxWidth: "600px"}}
        >
          <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">
            Our Services
          </p>
          <h2 className="display-5 mt-3 mb-5">We work on</h2>
        </div>
        {!isMobile && (
          <>
            <div className="row g-4 wow fadeInUp" data-wow-delay="0.3s">
              <div className="col-lg-4">
                <div className="nav nav-pills d-flex justify-content-between w-100 me-4">
                  {services &&
                    services.length > 0 &&
                    services.map((service, index) => {
                      return (
                        <button
                          className={`nav-link w-100 d-flex align-items-center text-start border p-3 mb-4 ${
                            index === 0 ? "active" : ""
                          }`}
                          data-bs-toggle="pill"
                          data-bs-target={`#services-tab-pane-${index}`}
                          type="button"
                          key={index}
                          aria-label={service.title}
                        >
                          <h5 className="m-0">
                            <i className="fa fa-bars me-3"></i>
                            {service.title}
                          </h5>
                        </button>
                      );
                    })}
                </div>
              </div>
              <div className="col-lg-8">
                <div className="tab-content w-100">
                  {services &&
                    services.length > 0 &&
                    services.map((service, index) => {
                      return (
                        <div
                          key={index}
                          className={`tab-pane fade ${
                            index === 0 ? "show active" : ""
                          }`}
                          id={`services-tab-pane-${index}`}
                        >
                          <div className="row g-4">
                            <div
                              className="col-md-6"
                              style={{minHeight: "350px"}}
                            >
                              <div className="position-relative h-100">
                                <img
                                  className="position-absolute rounded w-100 h-100"
                                  src={service.image}
                                  style={{objectFit: "cover"}}
                                  alt={service.intro}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <h3 className="mb-4 text-info">
                                {service.intro}
                              </h3>
                              <p className="mb-4">{service.description}</p>
                              {service.keyPoints.map((point, id) => (
                                <p key={id}>
                                  <i className="fa fa-check text-primary me-3"></i>
                                  {point}
                                </p>
                              ))}

                              <Link
                                rel="canonical"
                                to={service.href}
                                className="btn btn-primary py-3 px-5 mt-3"
                                onClick={() => {
                                  window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: "instant",
                                  });
                                }}
                              >
                                Guide to {service.title}
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </>
        )}
        {isMobile && <ServicesAccordion />}
      </div>
    </section>
  );
};

export default Services;
