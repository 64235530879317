import React from "react";
import Home from "../Home";
import SoftwareImg from "../../img/software-full.webp";
import RequirementAnalysis from "../../img/icons/requirement-analysis.png";
import DesignAndImplement from "../../img/icons/design-and-implement.png";
import TestingAndDeployment from "../../img/icons/testing-and-deployment.png";
import MaintenanceAndUpdates from "../../img/icons/maintenance-and-updates.png";
import {Helmet} from "react-helmet";
const SoftwareDevelopment = () => {
  return (
    <div className="bg-black text-light">
      <Helmet>
        <title>
          Customized software for your business by Softaim Technologies
        </title>

        <meta
          name="description"
          content="Softaim Technologies - Software solutions including Desktop applications, Web applications and mobile applications."
        />
        <meta
          name="keywords"
          content="softaim technologies,software services, desktop application, mobile application, web application, website development, web development, online selling, product listing online, sell online, hostpital management, clinic website, online appointment scheduling"
        ></meta>
      </Helmet>
      <Home
        data={{
          heading: "Softaim software development services",
          description: "Customised solution for your specific business needs",
          image: SoftwareImg,
          readMoreButton: false,
          // bgBlack: true,
        }}
      />
      <section
        id="services"
        className="container-fluid pb-5 p-100 text-light bg-black"
      >
        <div className="container">
          <div className="row g-4 align-items-end pb-4 mt-4">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
              <h2 className="display-5 mb-4 text-center">
                Tailored for your business needs
              </h2>
              <h5 className="lead text-center">
                Organizations have unique requirements for software due to their
                specific industry, business model, or mission.
              </h5>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={RequirementAnalysis}
                        alt="Requirement analysis"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Requirement analysis
                    </h3>

                    <ul className="card-text">
                      <li>Documenting the needs and goals of the software</li>
                      <li>
                        Prioritizing requirements based on their importance
                      </li>
                      <li> Requirement Validation with stakeholders</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={DesignAndImplement}
                        alt="Design and implement"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Design and implement
                    </h3>

                    <ul className="card-text">
                      <li> Architectural design and UI/UX</li>
                      <li> Database design</li>
                      <li> Coding</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={TestingAndDeployment}
                        alt="Testing and deployment"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Testing and deployment
                    </h3>

                    <ul className="card-text">
                      <li> Debugging</li>
                      <li> Performance optimization</li>
                      <li> Deployment and documentation</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={MaintenanceAndUpdates}
                        alt=" Maintenance and Support"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Maintenance and Support
                    </h3>

                    <ul className="card-text">
                      <li> Bug fixing and troubleshooting</li>
                      <li> Regular updates with latest technologies</li>
                      <li> User feedback and support</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SoftwareDevelopment;
