import React from "react";
import Home from "../Home";
import EcommerceImg from "../../img/ecommerce-full.webp";
import OnlineStore from "../../img/icons/ecommerce.png";
import Inventory from "../../img/icons/warehouse.png";
import CustomerSupport from "../../img/icons/customer-support.png";
import Analytics from "../../img/icons/analytics-and-insights.png";
import {Helmet} from "react-helmet";

const Ecommerce = () => {
  return (
    <div className="bg-black text-light">
      <Helmet>
        ‍<title>Ecommerce solutions by Softaim Technologies</title>‍
        <meta
          name="description"
          content="Softaim Technologies - Website development, online storefronts, payment processing, inventory management, and more. "
        />
        <meta
          name="keywords"
          content="softaim technologies,Product listings, Online retail, online shopping, manage inventory, website development, sell products online, mobile application development, receive payment online, payment integration, payment processing"
        ></meta>
      </Helmet>
      <Home
        data={{
          heading: "E-commerce industry offerings",
          description:
            "Enabling businesses to manage various aspects of their operations efficiently",
          image: EcommerceImg,
          readMoreButton: false,
          //   bgBlack: true,
        }}
      />
      <section
        id="services"
        className="container-fluid pb-5 p-100 text-light bg-black"
      >
        <div className="container">
          <div className="row g-4 align-items-end pb-4 mt-4">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
              <h2 className="display-5 mb-4 text-center">
                E-Commerce industry offerings
              </h2>
              <h5 className="lead text-center">
                Streamline operations, enhance the customer experience, and
                adapt to changing market dynamics
              </h5>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img src={OnlineStore} alt="Online store"></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">Online storefront</h3>

                    <ul className="card-text">
                      <li>Website or Mobile application</li>
                      <li>Admin Panel </li>
                      <li>User ratings and reviews</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img src={Inventory} alt="inventory"></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Inventory and Order Management
                    </h3>

                    <ul className="card-text">
                      <li> Track and manage inventory in real-time</li>
                      <li> Efficiently manage and fulfill orders</li>
                      <li> Order tracking and real-time updates</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img src={CustomerSupport} alt="customer support"></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Customer Support Tools
                    </h3>

                    <ul className="card-text">
                      <li>Helpdesk software</li>
                      <li>Live chat and chatbots</li>
                      <li>Email Ticketing Systems </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img src={Analytics} alt="analytics and reporting"></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Analytics and Reporting
                    </h3>

                    <ul className="card-text">
                      <li>Website traffic</li>
                      <li>Customer behavior and preferences </li>
                      <li>Order and sales data</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Ecommerce;
