import React from "react";
import Home from "../Home";
import HealthcareImg from "../../img/health-full.webp";
import Hospital from "../../img/icons/hospital.png";
import HealthRecords from "../../img/icons/health-records.png";
import Appointment from "../../img/icons/appointment.png";
import EConsultation from "../../img/icons/e-consultation.png";
import {Helmet} from "react-helmet";

const Healthcare = () => {
  return (
    <div className="bg-black text-light">
      <Helmet>
        <title>
          Software solutions for healthcare industry by Softaim Technologies
        </title>

        <meta
          name="description"
          content="Softaim Technologies - Hospital Information System, Manage patient data,
                Electronic Health Records, Online Appointment Scheduling, Telehealth and Telemedicine Platforms and more."
        />
        <meta
          name="keywords"
          content="softaim technologies,patient management, Patient Registration, Appointment Scheduling, patient
                      admissions, bed management, pharmacy management, manage patient medical records, manage
                      diagnoses reports, manage treatments,manage medications, online store test results"
        ></meta>
      </Helmet>
      <Home
        data={{
          heading: "Healthcare industry",
          description:
            "Enhance patient care, improve operational efficiency, and comply with regulatory requirements",
          image: HealthcareImg,
          readMoreButton: false,
          //   bgBlack: true,
        }}
      />
      <section
        id="services"
        className="container-fluid pb-5 p-100 text-light bg-black"
      >
        <div className="container">
          <div className="row g-4 align-items-end pb-4 mt-4">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
              <h2 className="display-5 mb-4 text-center">
                Solutions for Healthcare industry
              </h2>
              <h5 className="lead text-center">
                Software applications and systems to manage patient data,
                streamline operations, enhance patient care, and improve overall
                efficiency
              </h5>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={Hospital}
                        alt=" Hospital Information System"
                      ></img>
                    </h5>
                    <h3 className="card-title mb-3 pt-3">
                      Hospital Information System
                    </h3>
                    <p className="lead">
                      Manage the operations of hospitals, including patient
                      admissions, bed management, pharmacy management, and more.
                    </p>

                    <ul className="card-text">
                      <li>Patient Registration</li>
                      <li>Appointment Scheduling </li>
                      <li>Billing and Invoicing</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={HealthRecords}
                        alt="Electronic Health Records"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Electronic Health Records
                    </h3>
                    <p className="lead">
                      Store and manage patient medical records, including
                      diagnoses, treatments, medications, and test results
                    </p>
                    <ul className="card-text">
                      <li> Digital Documentation</li>
                      <li> Centralized Information</li>
                      <li> Improved Efficiency</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={Appointment}
                        alt="Online Appointment Scheduling"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Online Appointment Scheduling
                    </h3>
                    <p className="lead">
                      Enable patients to schedule appointments with healthcare
                      providers, laboratories, and other medical services.
                    </p>
                    <ul className="card-text">
                      <li>24/7 Accessibility</li>
                      <li>Real-Time Updates</li>
                      <li>Reminders and Notifications</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={EConsultation}
                        alt="Telehealth and Telemedicine Platforms"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Telehealth and Telemedicine Platforms
                    </h3>
                    <p className="lead">
                      Enabling healthcare providers to diagnose and treat
                      patients over video conferencing, phone, or chat.
                    </p>
                    <ul className="card-text">
                      <li>Remote consultations</li>
                      <li>Follow-Up and Post-Operative Care </li>
                      <li>File Sharing</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Healthcare;
