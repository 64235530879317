import React from "react";
import {services, industries, contactDetails} from "../data";
import {Link} from "react-router-dom";

const Footer = () => {
  return (
    <footer
      id="footer"
      className="container-fluid bg-black text-light footer py-1"
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <h1 className="display-6  mb-4">Our Office</h1>
            <div className="d-flex">
              <p>
                <i className="fa fa-map-marker-alt me-3"></i>
              </p>
              <p>{contactDetails.address}</p>
            </div>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3"></i>
              {contactDetails.mobile}
            </p>
            <p className="mb-2">
              <i className="fa fa-envelope me-3"></i>
              {contactDetails.email}
            </p>
            <div className="d-flex pt-2">
              {/* <a
                className="btn btn-square btn-outline-light rounded-circle me-2"
                href=""
              >
                <i className="fab fa-twitter"></i>
              </a> */}
              <a
                className="btn btn-square btn-outline-light rounded-circle me-2"
                href="https://www.facebook.com/softaimTech"
                aria-label="Softaim Technologies Facebook Profile"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              {/* <a
                className="btn btn-square btn-outline-light rounded-circle me-2"
                href=""
              >
                <i className="fab fa-youtube"></i>
              </a> */}
              <a
                className="btn btn-square btn-outline-light rounded-circle me-2"
                href="https://www.linkedin.com/company/softaimtech"
                aria-label="Softaim Technologies LinkedIn Profile"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4">
            <h1 className="display-6  mb-4">Services</h1>
            {services &&
              services.length > 0 &&
              services.map((service, index) => (
                <a
                  className="btn btn-link"
                  href={service.href}
                  key={index}
                  aria-label={service.title}
                  // onClick={() => {
                  //   window.scrollTo({
                  //     top: 0,
                  //     left: 0,
                  //     behavior: "instant",
                  //   });
                  // }}
                >
                  {service.title}
                </a>
              ))}
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4">
            <h1 className="display-6  mb-4">Industries</h1>
            {industries &&
              industries.length > 0 &&
              industries.map((industry, index) => (
                <a
                  className="btn btn-link"
                  href={industry.href}
                  key={index}
                  aria-label={industry.title}
                  // onClick={() => {
                  //   window.scrollTo({
                  //     top: 0,
                  //     left: 0,
                  //     behavior: "instant",
                  //   });
                  // }}
                >
                  {industry.title}
                </a>
              ))}
          </div>
          {/* <div className="col-lg-4 4">
            <h4 className="display-6  mb-4">Newsletter</h4>
            <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
            <div className="position-relative w-100">
              <input
                className="form-control bg-white border-0 w-100 py-3 ps-4 pe-5"
                type="text"
                placeholder="Your email"
              />
              <button
                type="button"
                className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
              >
                SignUp
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
