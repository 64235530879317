import React from "react";
import Home from "../Home";
import IndustrialIOTImg from "../../img/iiot-full.webp";
import ConnectedSensors from "../../img/icons/connected-sensors.png";
import DataVisualization from "../../img/icons/data-visualization.png";
import CentralizedData from "../../img/icons/centralized-data.png";
import Efficiency from "../../img/icons/efficiency.png";
import {Helmet} from "react-helmet";

const IndustrialIOT = () => {
  return (
    <div className="bg-black text-light">
      <Helmet>
        <title>
          Software solutions for industrial internet of things by Softaim
          Technologies
        </title>

        <meta
          name="description"
          content="Softaim Technologies - Connected Sensors and Devices, Data Analytics and Visualization, Centralized Data Repository, Remote access and control of equipments and more."
        />
        <meta
          name="keywords"
          content="softaim technologies,iot integration, robot operaing system, Sensor connectivity, sensor communication, Data collection and processing, Real-Time Monitoring of sensor data, sensor data to cloud, sensor data online storage, iot connectivity "
        ></meta>
      </Helmet>
      <Home
        data={{
          heading: "Industrial Internet of Things (IIoT) services",
          description:
            "Improve operational efficiency, reduce costs, and enhance overall productivity",
          image: IndustrialIOTImg,
          readMoreButton: false,
          bgBlack: true,
        }}
      />
      <section
        id="services"
        className="container-fluid pb-5 p-100 text-light bg-black"
      >
        <div className="container">
          <div className="row g-4 align-items-end pb-4 mt-4">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
              <h2 className="display-5 mb-4 text-center">
                Industrial Internet of Things
              </h2>
              <h5 className="lead text-center">
                Network of sensors and devices that are deployed within
                industrial environments
              </h5>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={ConnectedSensors}
                        alt="Connected Sensors and Devices"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Connected Sensors and Devices
                    </h3>

                    <ul className="card-text">
                      <li>Network of interconnected devices and sensors</li>
                      <li>Sensor data to cloud based platforms </li>
                      <li>Real time data exchange</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={DataVisualization}
                        alt="Data Analytics and Visualization"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Data Analytics and Visualization
                    </h3>

                    <ul className="card-text">
                      <li> Data in the form of dashboard and reports</li>
                      <li> Real-time insights into industrial operations</li>
                      <li> Automated decision-making and process control</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={CentralizedData}
                        alt="Centralized Data Repository"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Centralized Data Repository
                    </h3>

                    <ul className="card-text">
                      <li>Remote access and control of equipments</li>
                      <li>
                        Data Security through encryption and authentication
                      </li>
                      <li> Maintain data integrity</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={Efficiency}
                        alt="Efficiency and Productivity"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Efficiency and Productivity
                    </h3>

                    <ul className="card-text">
                      <li>
                        Improve efficiency and productivity by providing
                        data-driven insights
                      </li>
                      <li> Identify bottlenecks</li>
                      <li> Reduce waste in industrial process</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default IndustrialIOT;
