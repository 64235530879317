import * as React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";

import "./index.css";
import "./App.css";
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import SoftwareDevelopment from "./Components/services/SoftwareDevelopment";
import Page404 from "./Components/Page404";
import Layout from "./Components/Layout";
import ServicesDetail from "./Components/ServicesDetail";
import IndustriesDetail from "./Components/IndustriesDetail";
import MobileApplications from "./Components/services/MobileApplications";
import WebDevelopment from "./Components/services/WebDevelopment";
import ERPandCRM from "./Components/services/ERPandCRM";
import IndustrialIOT from "./Components/services/IndustrialIOT";
import Ecommerce from "./Components/industries/Ecommerce";
import Educational from "./Components/industries/Educational";
import FactoryAutomation from "./Components/industries/FactoryAutomation";
import Food from "./Components/industries/Food";
import Healthcare from "./Components/industries/Healthcare";
import HospitalityAndTourism from "./Components/industries/HospitalityAndTourism";
import Industry4_0 from "./Components/industries/Industry4_0";
import Transportation from "./Components/industries/Transportation";
import PrivacyPolicy from "./Components/policies/PrivacyPolicy";
import TermsOfUse from "./Components/policies/TermsOfUse";
import PolicyLayout from "./Components/PolicyLayout";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <App />,
      },
      {
        path: "services",
        element: <ServicesDetail />,
        children: [
          {path: "software-development", element: <SoftwareDevelopment />},
          {path: "web-development", element: <WebDevelopment />},
          {path: "mobile-application", element: <MobileApplications />},
          {path: "erp-and-crm", element: <ERPandCRM />},
          {path: "industrial-iot", element: <IndustrialIOT />},
        ],
      },
      {
        path: "industries",
        element: <IndustriesDetail />,
        children: [
          {path: "ecommerce", element: <Ecommerce />},
          {path: "educational-and-institutional", element: <Educational />},
          {path: "factory-automation", element: <FactoryAutomation />},
          {path: "food-industry", element: <Food />},
          {path: "healthcare", element: <Healthcare />},
          {path: "hospitality-and-tourism", element: <HospitalityAndTourism />},
          {path: "industry4", element: <Industry4_0 />},
          {path: "transportation", element: <Transportation />},
        ],
      },
    ],
  },
  {
    path: "policies",
    element: <PolicyLayout />,
    children: [
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "terms-of-use",
        element: <TermsOfUse />,
      },
    ],
  },

  {
    path: "*",
    element: <Page404 />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
