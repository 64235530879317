import React from "react";
import {services} from "../data";
import {Link} from "react-router-dom";

const ServicesAccordion = () => {
  return (
    <section
      className="row g-4 accordion accordion-flush border-top"
      id="serviceAccordion"
    >
      {services &&
        services.length > 0 &&
        services.map((service, index) => {
          return (
            <div
              className={`accordion-item  wow fadeInLeft bg-black ${
                index === services.length - 1 ? " border-bottom" : ""
              } `}
              key={index}
              data-wow-delay={`${(index + 1) * 0.1 + 0.1}s`}
            >
              <h2 className="accordion-header">
                <button
                  className={`accordion-button font-opensans text-light bg-black shadow-none ps-0 ${
                    index !== 0 ? "collapsed" : ""
                  }`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${index}`}
                  aria-expanded={index === 0 ? "true" : "false"}
                  aria-controls={`collapse${index}`}
                  style={{fontSize: "1.35rem"}}
                  area-label={service.title}
                >
                  {service.titleSmall !== ""
                    ? service.titleSmall
                    : service.title}
                </button>
              </h2>
              <div
                id={`collapse${index}`}
                className={`accordion-collapse collapse text-light ${
                  index === 0 ? "show" : ""
                }`}
                data-bs-parent="#serviceAccordion"
              >
                <div className="accordion-body p-0">
                  <div className="card text-light bg-black rounded-0">
                    <div className="card-body">
                      <p className="lead card-title fs-2">{service.intro}</p>
                      <p className="card-text fs-1">{service.description}</p>
                      <Link
                        rel="canonical"
                        to={service.href}
                        className="btn btn-primary btn-sm mb-2"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "instant",
                          });
                        }}
                      >
                        Guide to {service.title}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </section>
  );
};

export default ServicesAccordion;
