import React from "react";
import {lazy} from "react";
import {homePageData} from "./data";
import {ScrollRestoration} from "react-router-dom";
const About = lazy(() => import("./Components/About.js"));
const Features = lazy(() => import("./Components/Features.js"));
const Services = lazy(() => import("./Components/Services.js"));
const Callback = lazy(() => import("./Components/Callback.js"));
const Industries = lazy(() => import("./Components/Industries.js"));
const Home = lazy(() => import("./Components/Home.js"));

function App() {
  return (
    <main
      data-bs-target="#navbar"
      data-bs-offset="50"
      data-bs-smooth-scroll="true"
      tabIndex="0"
    >
      <Home data={homePageData} />
      <Services />
      <Industries />
      <Features />
      <About />
      <Callback />
      <ScrollRestoration />
    </main>
  );
}

export default App;
