import React from "react";

import {Outlet} from "react-router-dom";
import Footer from "./Footer";
import CustomGrowSpinner from "./CustomGrowSpinner";
import Copyright from "./Copyright";
import CustomNavbar from "./CustomNavbar";

const PolicyLayout = () => {
  return (
    <main>
      <CustomGrowSpinner />
      <CustomNavbar showNavbar={false} />
      <Outlet />
      <Footer />
      <Copyright />
      <a
        href="#"
        className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top fadeOut shadow-none border-0"
        aria-label="Go to home"
      >
        <i className="bi bi-arrow-up"></i>
      </a>
    </main>
  );
};

export default PolicyLayout;
