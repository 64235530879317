import AboutImg from "../img/about.webp";

const About = () => {
  return (
    <>
      <section
        id="about"
        className="container-fluid pb-5 p-100 bg-black text-light"
      >
        <div className="container">
          <div className="row g-4 align-items-end mb-4">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <img
                className="img-fluid rounded"
                src={AboutImg}
                alt="about-softaim"
              />
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">
                About Us
              </p>
              <h2 className="display-5 mb-4">
                Helping our clients to grow their business
              </h2>
              <p className="">
                Delivering cutting-edge solutions that empower businesses to
                thrive in the digital age.
              </p>
              <p className="mb-4">
                Our commitment to excellence, dedication to our clients, and a
                passionate team of experienced professionals have made us a
                trusted partner.
              </p>
              <div className="rounded">
                <nav>
                  <div
                    className="nav nav-tabs mb-3"
                    id="nav-tab"
                    role="tablist"
                  >
                    <button
                      className="nav-link fw-semi-bold active"
                      id="nav-mission-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-mission"
                      type="button"
                      role="tab"
                      aria-controls="nav-mission"
                      aria-selected="true"
                    >
                      Aim
                    </button>
                    <button
                      className="nav-link fw-semi-bold"
                      id="nav-vision-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-vision"
                      type="button"
                      role="tab"
                      aria-controls="nav-vision"
                      aria-selected="false"
                    >
                      Vision
                    </button>
                    <button
                      className="nav-link fw-semi-bold"
                      id="nav-collab-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-collab"
                      type="button"
                      role="tab"
                      aria-controls="nav-collab"
                      aria-selected="false"
                    >
                      Let's Collaborate
                    </button>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-mission"
                    role="tabpanel"
                    aria-labelledby="nav-mission-tab"
                  >
                    <p>
                      Our mission is to exceed our clients' expectations by
                      delivering high-quality software services that drive
                      measurable results.
                    </p>
                    <p className="mb-0">
                      We focus on building long-lasting partnerships and
                      continually expanding our knowledge to stay at the
                      forefront of the ever-evolving technology landscape.
                    </p>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-vision"
                    role="tabpanel"
                    aria-labelledby="nav-vision-tab"
                  >
                    <p>
                      Our vision is to revolutionize the digital landscape by
                      providing innovative and sustainable software solutions.
                    </p>
                    <p className="mb-0">
                      We aim to empower businesses with technology that enhances
                      their efficiency, competitiveness, and growth potential.
                    </p>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-collab"
                    role="tabpanel"
                    aria-labelledby="nav-collab-tab"
                  >
                    <p>
                      Whether you're a startup looking to launch your first app
                      or an established enterprise seeking to optimize your
                      software ecosystem, Softaim is here to help.
                    </p>
                    <p className="mb-0">
                      Contact us today to discuss your project, and let's embark
                      on a journey of innovation and success together.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="border rounded p-4 wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <div className="row g-4">
              <div className="col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                <div className="h-100">
                  <div className="d-flex">
                    <div className="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                      <i className="fa fa-times text-white"></i>
                    </div>
                    <div className="ps-3">
                      <h4 className="text-info">No Hidden Cost</h4>
                      <span>
                        We believe in full disclosure. You can rest assured that
                        we won't impose unexpected charges or fees during the
                        course of your project.
                      </span>
                    </div>
                    <div className="border-end d-none d-lg-block"></div>
                  </div>
                  <div className="border-bottom mt-4 d-block d-lg-none"></div>
                </div>
              </div>
              <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                <div className="h-100">
                  <div className="d-flex">
                    <div className="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                      <i className="fa fa-users text-white"></i>
                    </div>
                    <div className="ps-3">
                      <h4 className="text-info">Dedicated Team</h4>
                      <span>
                        Our team comprises highly skilled professionals with a
                        deep understanding of software development, design, and
                        implementation.
                      </span>
                    </div>
                    <div className="border-end d-none d-lg-block"></div>
                  </div>
                  <div className="border-bottom mt-4 d-block d-lg-none"></div>
                </div>
              </div>
              <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                <div className="h-100">
                  <div className="d-flex">
                    <div className="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                      <i className="fa fa-wrench text-white"></i>
                    </div>
                    <div className="ps-3">
                      <h4 className="text-info">Maintenance and Support</h4>
                      <span>
                        Our commitment doesn't end with project delivery. We
                        provide ongoing support and maintenance services to keep
                        your software running smoothly.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
