import React from "react";
import Home from "../Home";
import ERPandCRMImg from "../../img/erp-full.webp";
import SupplyChain from "../../img/icons/supply-chain.png";
import HumanResource from "../../img/icons/human-resource.png";
import FinanceManagement from "../../img/icons/finance-management.png";
import Manufacturing from "../../img/icons/manufacturing.png";
import CustomerData from "../../img/icons/customer-data.png";
import SalesAndLead from "../../img/icons/sales-and-lead.png";
import CustomerSupport from "../../img/icons/customer-support.png";
import Analytics from "../../img/icons/analytics-and-insights.png";
import {Helmet} from "react-helmet";

const ERPandCRM = () => {
  return (
    <div className="bg-black text-light">
      <Helmet>
        <title>ERP and CRM softwares by Softaim Technologies</title>

        <meta
          name="description"
          content="Softaim Technologies - Human resources management, Budgeting and finance management, Customer data management, Sales/Lead management and more."
        />
        <meta
          name="keywords"
          content="softaim technologies,Employee records, payment management, customer information store, company management, resource planning, sales management, lead management, finance management, hr management software "
        ></meta>
      </Helmet>
      <Home
        data={{
          heading: "Softaim ERP and CRM solutions",
          description: "Seamless flow of information across the organization",
          image: ERPandCRMImg,
          readMoreButton: false,
          // bgBlack: true,
        }}
      />
      <section
        id="services"
        className="container-fluid pb-5 p-100 text-light bg-black"
      >
        <div className="container">
          <div className="row g-4 align-items-end pb-4 mt-4">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
              <h2 className="display-5 mb-4 text-center">
                Enterprise Resource Planning (ERP)
              </h2>
              <h5 className="lead text-center">
                Designed to facilitate and streamline various aspects of
                business operations
              </h5>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={SupplyChain}
                        alt="Supply Chain Management"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Supply Chain Management
                    </h3>

                    <ul className="card-text">
                      <li>Optimize and track the supply chain process</li>
                      <li>Inventory management </li>
                      <li>Order fulfillment</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={HumanResource}
                        alt="Human Resources Management"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Human Resources Management
                    </h3>

                    <ul className="card-text">
                      <li> Employee records</li>
                      <li> Payroll management</li>
                      <li> Workforce planning</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={FinanceManagement}
                        alt="Financial Management"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Financial Management
                    </h3>

                    <ul className="card-text">
                      <li> Manage accounting and budgeting</li>
                      <li> Financial reporting and analysis</li>
                      <li> Increased scope of applications</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={Manufacturing}
                        alt="Manufacturing and Production"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Manufacturing and Production
                    </h3>

                    <ul className="card-text">
                      <li>Production planning</li>
                      <li> Quality control</li>
                      <li> Shop floor operations</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-4 align-items-end pb-4 mt-4">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
              <h2 className="display-5 mb-4 text-center">
                Customer Relationship Management (CRM)
              </h2>
              <h5 className="lead text-center">
                Designed specifically to manage customer relationships and
                interactions
              </h5>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={CustomerData}
                        alt="Customer Data Management"
                      ></img>
                    </h5>
                    <h3 className="card-title mb-3 pt-3">
                      Customer Data Management
                    </h3>

                    <ul className="card-text">
                      <li> Centralize customer information</li>
                      <li> Purchase history</li>
                      <li> Preferences and interaction history</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={SalesAndLead}
                        alt="Sales and Lead Management"
                      ></img>
                    </h5>
                    <h3 className="card-title mb-3 pt-3">
                      Sales and Lead Management
                    </h3>

                    <ul className="card-text">
                      <li>Tracking sales leads</li>
                      <li>Managing sales pipelines </li>
                      <li>Automating sales processes</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={CustomerSupport}
                        alt="Customer Support and Service"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Customer Support and Service
                    </h3>

                    <ul className="card-text">
                      <li>Ticket management</li>
                      <li> Case tracking, and knowledge bases</li>
                      <li> Customer inquiries and issue resolution</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img src={Analytics} alt="Analytics and Reporting" />
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Analytics and Reporting
                    </h3>

                    <ul className="card-text">
                      <li>Measure the effectiveness of marketing campaigns</li>
                      <li> Sales performance and customer interactions</li>
                      <li> Insights to make data-driven decisions</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ERPandCRM;
