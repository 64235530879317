import React from "react";
import {Outlet} from "react-router-dom";
import Features from "./Features";
import About from "./About";
import Callback from "./Callback";
import Services from "./Services";
export default function IndustriesDetail() {
  return (
    <main
      data-bs-target="#navbar"
      data-bs-offset="50"
      data-bs-smooth-scroll="true"
      tabIndex="0"
    >
      <Outlet />
      <Services />
      <Features />
      <About />
      <Callback />
    </main>
  );
}
