import React from "react";
import {Helmet} from "react-helmet";
import CustomNavbar from "./CustomNavbar";
import Footer from "./Footer";
import Copyright from "./Copyright";
import Services from "./Services";
import Industries from "./Industries";
import Features from "./Features";
import Callback from "./Callback";
import About from "./About";

const Page404 = () => {
  return (
    <div className="bg-black text-light">
      <Helmet>
        <title>Sorry! No page found</title>

        <meta
          name="description"
          content="Softaim Technologies - Human resources management, Budgeting and finance management, Customer data management, Sales/Lead management and more."
        />
        <meta
          name="keywords"
          content="softaim technologies,Employee records, payment management, customer information store, company management, resource planning, sales management, lead management, finance management, hr management software "
        ></meta>
      </Helmet>
      <CustomNavbar />

      <section
        id="services"
        className="container-fluid pb-5 p-100 text-light bg-black"
      >
        <div className="container">
          <div className="row g-4 align-items-end pb-4 mt-4">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
              <h2 className="display-5 mb-4 text-center">
                Sorry! No data found
              </h2>
              <h5 className="lead text-center">
                Seems like the page have been removed or the url is mis-spelled.
              </h5>
            </div>
          </div>
        </div>
      </section>
      <Services />
      <Industries />
      <Features />
      <About />
      <Callback />
      <Footer />
      <Copyright />
    </div>
  );
};

export default Page404;
