import React from "react";
import Home from "../Home";
import FoodImg from "../../img/food-full.webp";
import Inventory from "../../img/icons/warehouse.png";
import SCM from "../../img/icons/supply-chain.png";
import CRM from "../../img/icons/customer-support.png";
import Delivery from "../../img/icons/mobile-app.png";
import {Helmet} from "react-helmet";

const Food = () => {
  return (
    <div className="bg-black text-light">
      <Helmet>
        <title>
          Software solutions for food industry by Softaim Technologies
        </title>
        ‍
        <meta
          name="description"
          content="Softaim Technologies - Online food ordering, raw products management, record of expiry dates, supply chain management, customer relationship management and more."
        />
        <meta
          name="keywords"
          content="softaim technologies,track food expiry date, online food ordering, raw products management, supply chain management, customer relationship management"
        ></meta>
      </Helmet>
      <Home
        data={{
          heading: "Food industry",
          description:
            "Food safety, compliance, quality control, and overall customer satisfaction",
          image: FoodImg,
          readMoreButton: false,
          //   bgBlack: true,
        }}
      />
      <section
        id="services"
        className="container-fluid pb-5 p-100 text-light bg-black"
      >
        <div className="container">
          <div className="row g-4 align-items-end pb-4 mt-4">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
              <h2 className="display-5 mb-4 text-center">
                Food industry offerings
              </h2>
              <h5 className="lead text-center">
                Enhancing efficiency, safety, and quality throughout the entire
                food production and distribution process
              </h5>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img src={Inventory} alt="inventory management"></img>
                    </h5>
                    <h3 className="card-title mb-3 pt-3">
                      Inventory Management
                    </h3>
                    <p className="lead">
                      Overseeing the ordering, storing, and use of a company's
                      materials, components, and finished products.
                    </p>

                    <ul className="card-text">
                      <li>Track inventory levels</li>
                      <li>Record of Expiration dates </li>
                      <li>Waste reduction</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img src={SCM} alt="supply chain management"></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Supply Chain Management
                    </h3>
                    <p className="lead">
                      Optimize supply chains, monitor shipments, and ensure that
                      products are delivered on time
                    </p>
                    <ul className="card-text">
                      <li> Supplier Management</li>
                      <li> Demand Forecasting</li>
                      <li> Food Delivery and E-Commerce</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={Delivery}
                        alt="Consumer Apps and Food Delivery Platforms"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Consumer Apps and Food Delivery Platforms
                    </h3>
                    <p className="lead">
                      Deliver safe, high-quality food products to consumers
                      while minimizing costs and environmental impact.
                    </p>
                    <ul className="card-text">
                      <li>Consumer-facing app and website for ordering food</li>
                      <li>Making reservations</li>
                      <li>Accessing nutritional information</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={CRM}
                        alt="Customer Relationship Management"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Customer Relationship Management (CRM)
                    </h3>
                    <p className="lead">
                      Manage relationships with suppliers and customers, helping
                      to streamline communication and improve customer service.
                    </p>
                    <ul className="card-text">
                      <li>Customer Data Collection</li>
                      <li>Reservations and Table Management </li>
                      <li>Order Tracking and Status Updates</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Food;
