import React, {useState} from "react";
import {industries, services, contactDetails} from "../data";
import {Link} from "react-router-dom";
import ReactLogo from "./../img/logo-transparent-svg.svg";

const CustomNavbar = ({showNavbar = true}) => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  return (
    <div className="container-fluid fixed-top px-0">
      <div className="top-bar row gx-0 align-items-center d-none d-lg-flex">
        <div className="col-lg-8 ps-5 text-start">
          <small>
            <i className="fa fa-map-marker-alt text-primary me-2"></i>
            {contactDetails.address}
          </small>
          <small className="ms-4">
            <i className="fa fa-clock text-primary me-2"></i>9.00 am - 7.00 pm
          </small>
        </div>
        <div className="col-lg-4 pe-5 text-end">
          <small>
            <i className="fa fa-envelope text-primary me-2"></i>
            {contactDetails.email}
          </small>
          <small className="ms-4">
            <i className="fa fa-phone-alt text-primary me-2"></i>
            {contactDetails.mobile}
          </small>
        </div>
      </div>
      <nav
        id="navbar"
        className="navbar navbar-expand-lg navbar-dark py-lg-0 px-lg-5"
      >
        <a
          href="/"
          className="navbar-brand ms-4 ms-lg-0"
          aria-label="go to home"
        >
          <h2
            className="display-6 text-primary m-0 font-opensans"
            style={{letterSpacing: "1px"}}
          >
            <img src={ReactLogo} width="230" alt="softaim technologies logo" />
            {/* Softaim */}
          </h2>
        </a>
        {showNavbar && (
          <>
            <button
              id="navbar-toggler"
              type="button"
              className={`${
                isNavCollapsed ? "collapsed " : ""
              } navbar-toggler me-4 shadow-none d-flex d-lg-none flex-column justify-content-around`}
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              onClick={() => {
                setIsNavCollapsed(!isNavCollapsed);
              }}
              aria-label="Softaim Technologies Navbar"
            >
              {/* <span className="navbar-toggler-icon"></span> */}
              <span className="toggler-icon start-bar"></span>
              <span className="toggler-icon middle-bar"></span>
              <span className="toggler-icon end-bar"></span>
            </button>
            <div
              className={`${
                isNavCollapsed ? "" : "show"
              } collapse navbar-collapse`}
              id="navbarCollapse"
            >
              <div className="navbar-nav ms-auto p-4 p-lg-0">
                <div className="nav-item dropdown">
                  <a
                    href="#services"
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-label="Read about services offered by Softaim Technologies"
                  >
                    Services
                  </a>
                  <div className="dropdown-menu border-dark bg-black m-0 p-sm-3 p-lg-3">
                    {services &&
                      services.length > 0 &&
                      services.map((service, index) => (
                        <Link
                          rel="canonical"
                          to={service.href}
                          onClick={() => {
                            setIsNavCollapsed(true);
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "instant",
                            });
                          }}
                          key={index}
                          className="dropdown-item"
                          aria-label={service.title}
                        >
                          {service.title}
                        </Link>
                      ))}
                  </div>
                </div>
                <div className="nav-item dropdown">
                  <a
                    href="#industries"
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    Industries
                  </a>
                  <div className="dropdown-menu border-dark bg-black m-0 p-sm-3 p-lg-3">
                    <div className="container p-0">
                      <div className="row">
                        <div className="col pe-5">
                          {industries &&
                            industries.length > 0 &&
                            industries.map((industry, index) => {
                              if (index < 4)
                                return (
                                  <Link
                                    rel="canonical"
                                    key={index}
                                    to={industry.href}
                                    className="dropdown-item"
                                    onClick={() => {
                                      setIsNavCollapsed(true);
                                      window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: "instant",
                                      });
                                    }}
                                    aria-label={industry.title}
                                  >
                                    {industry.title}
                                  </Link>
                                );
                            })}
                        </div>
                        <div className="col">
                          {industries &&
                            industries.length > 0 &&
                            industries.map((industry, index) => {
                              if (index > 3)
                                return (
                                  <Link
                                    rel="canonical"
                                    key={index}
                                    to={industry.href}
                                    className="dropdown-item"
                                    onClick={() => {
                                      setIsNavCollapsed(true);
                                      window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: "instant",
                                      });
                                    }}
                                    aria-label={industry.title}
                                  >
                                    {industry.title}
                                  </Link>
                                );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <a
                  href="#features"
                  className="nav-item nav-link"
                  onClick={() => {
                    setIsNavCollapsed(true);
                  }}
                  aria-label="Softaim Technologies features"
                >
                  Features
                </a>
                <a
                  href="#about"
                  className="nav-item nav-link"
                  onClick={() => {
                    setIsNavCollapsed(true);
                  }}
                  aria-label="About Softaim Technologies"
                >
                  About us
                </a>
                <a
                  href="#contact"
                  className="nav-item nav-link"
                  onClick={() => {
                    setIsNavCollapsed(true);
                  }}
                  aria-label="Contact us"
                >
                  Contact us
                </a>
                {/* <a
                  href="https://blogs.softaim.in"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-item nav-link"
                  onClick={() => {
                    setIsNavCollapsed(true);
                  }}
                  aria-label="Read our blogs"
                >
                  Our Blogs
                </a> */}
              </div>
              <div className="d-none d-lg-flex ms-2">
                <a
                  className="btn btn-light btn-sm-square rounded-circle ms-3"
                  href="https://www.facebook.com/softaimTech"
                  aria-label="Softaim Technologies Facebook Profile"
                >
                  <small className="fab fa-facebook-f text-primary"></small>
                </a>
                {/* <a
              className="btn btn-light btn-sm-square rounded-circle ms-3"
              href=""
            >
              <small className="fab fa-twitter text-primary"></small>
            </a> */}
                <a
                  className="btn btn-light btn-sm-square rounded-circle ms-3"
                  href="https://www.linkedin.com/company/softaimtech"
                  aria-label="Softaim Technologies LinkedIn Profile"
                >
                  <small className="fab fa-linkedin-in text-primary"></small>
                </a>
              </div>
            </div>
          </>
        )}
      </nav>
    </div>
  );
};

export default CustomNavbar;
