import React from "react";
import {industries} from "../data";
import {Link} from "react-router-dom";

const Industries = () => {
  return (
    <section
      id="industries"
      className="container-fluid pb-5 p-100 bg-black text-light"
    >
      <div className="container p-0">
        <div
          className="text-center mx-auto wow fadeInUp"
          data-wow-delay="0.3s"
          style={{maxWidth: "600px"}}
        >
          <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">
            Industries we innovate
          </p>
          <h2 className="display-5 mt-4 mb-4">Industries that grow with us</h2>
        </div>
        <section className="row justify-content-around align-items-start flex-wrap">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 g-4 p-0">
            {industries &&
              industries.length > 0 &&
              industries.map(
                (industry, index) =>
                  index < 8 && (
                    <div
                      className="col wow fadeIn"
                      key={index}
                      data-wow-delay={`${(index + 1) * 0.1 + 0.3}s`}
                    >
                      <div className="card">
                        <img
                          src={industry.image}
                          className="card-img-top"
                          alt={industry.title}
                        />
                        <div className="card-body bg-black text-light rounded-bottom">
                          <h4 className="card-title mb-3 text-info">
                            {industry.title}
                          </h4>
                          <div className="card-text mt-2">
                            {industry.keyPoints.map((point, id) => (
                              <div key={id} className="d-flex">
                                <p className="pe-2 pe-sm-3 pe-lg-4 mb-3">
                                  <i className="fa fa-check text-primary"></i>
                                </p>
                                <p className="mb-3">{point}</p>
                              </div>
                            ))}
                            <p className="text-end m-0 me-2">
                              <Link
                                rel="canonical"
                                className="fw-semi-bold text-decoration-none"
                                to={industry.href}
                                onClick={() => {
                                  window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: "instant",
                                  });
                                }}
                              >
                                More about {industry.title}
                                {/* <i className="fa fa-chevron-right fa-fade ms-2"></i> */}
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
          </div>
        </section>
      </div>
    </section>
  );
};

export default Industries;
