import React from "react";
import Home from "../Home";
import MobileApplicationsImg from "../../img/mobile-applications.webp";
import NativeApp from "../../img/icons/native-app.png";
import UserExperience from "../../img/icons/user-experience.png";
import PWA from "../../img/icons/pwa.png";
import UserEngagement from "../../img/icons/user-engagement.png";
import {Helmet} from "react-helmet";

const MobileApplications = () => {
  return (
    <div className="bg-black text-light">
      <Helmet>
        <title>
          Dedicated Mobile applications for your business by Softaim
          Technologies
        </title>

        <meta
          name="description"
          content="Softaim Technologies - Smoother performance and native features, Access to Device Features, Increased Customer Engagement and more."
        />
        <meta
          name="keywords"
          content="softaim technologies,mobile app development, android application for my business, mobile application for my business, mobile app design,custom mobile app, cross-platform app, hybrid app development"
        ></meta>
      </Helmet>
      <Home
        data={{
          heading: "Mobile applications",
          description:
            "Designed for specific operating systems, most engaging and user-friendly experience",
          image: MobileApplicationsImg,
          readMoreButton: false,
          // bgBlack: true,
        }}
      />
      <section
        id="services"
        className="container-fluid pb-5 p-100 text-light bg-black"
      >
        <div className="container">
          <div className="row g-4 align-items-end pb-4 mt-4">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
              <h2 className="display-5 mb-4 text-center">
                Smoother performance and native features
              </h2>
              <h5 className="lead text-center">
                Make it easier for customers to engage with your business and
                access your products or services
              </h5>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img src={PWA} alt="Native Apps"></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">Native Apps</h3>

                    <ul className="card-text">
                      <li>Built for a specific platform</li>
                      <li>Best performance </li>
                      <li>Device native features</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={UserExperience}
                        alt="Improved user experience"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Improved user experience
                    </h3>

                    <ul className="card-text">
                      <li> Fast loading time</li>
                      <li> User friendly interface</li>
                      <li> Optimized images</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={NativeApp}
                        alt="Access to Device Features"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Access to Device Features
                    </h3>

                    <ul className="card-text">
                      <li> GPS, Camera, Accelerometer and more</li>
                      <li> Enhanced functionality</li>
                      <li> Increased scope of applications</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={UserEngagement}
                        alt="Increased Customer Engagement"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Increased Customer Engagement
                    </h3>

                    <ul className="card-text">
                      <li>Integrate with other apps on the device</li>
                      <li> In-app purchases</li>
                      <li> Loyalty programs and social sharing</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MobileApplications;
