import React from "react";
import Home from "../Home";
import HospitalityAndTourismImg from "../../img/hotel-full.webp";
import HMS from "../../img/icons/hms.png";
import Booking from "../../img/icons/booking.png";
import Activity from "../../img/icons/activity.png";
import Event from "../../img/icons/event.png";
import {Helmet} from "react-helmet";

const HospitalityAndTourism = () => {
  return (
    <div className="bg-black text-light">
      <Helmet>
        <title>
          Software services for hospitality and tourism industry by Softaim
          Technologies
        </title>

        <meta
          name="description"
          content="Softaim Technologies - Hotel Management System, Online Booking and Reservation Systems, Tour and Activity Booking Software, Event Management Software and more."
        />
        <meta
          name="keywords"
          content="softaim technologies,hotel management, online booking, online reservation, guest management, manage reservations,
                      manage check-ins, manage check-outs,manage billing, manage room assignments, event registration,budget and expense tracking"
        ></meta>
      </Helmet>
      <Home
        data={{
          heading: "Hospitality and Tourism",
          description:
            "Improve operations, customer satisfaction, and overall business performance",
          image: HospitalityAndTourismImg,
          readMoreButton: false,
          //   bgBlack: true,
        }}
      />
      <section
        id="services"
        className="container-fluid pb-5 p-100 text-light bg-black"
      >
        <div className="container">
          <div className="row g-4 align-items-end pb-4 mt-4">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
              <h2 className="display-5 mb-4 text-center">
                Software solutions for Hospitality and Tourism industry
              </h2>
              <h5 className="lead text-center">
                Automate various administrative tasks, such as reservations,
                check-ins, check-outs, and billing, reducing manual errors and
                saving time.
              </h5>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img src={HMS} alt="Hotel Management System"></img>
                    </h5>
                    <h3 className="card-title mb-3 pt-3">
                      Hotel Management System
                    </h3>
                    <p className="lead">
                      Helps hotels and accommodations manage reservations,
                      check-ins, check-outs, billing, and room assignments.
                    </p>
                    <ul className="card-text">
                      <li>Guest Profile Management</li>
                      <li>Room Inventory Management</li>
                      <li>Housekeeping Management </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={Booking}
                        alt="Online Booking and Reservation Systems"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Online Booking and Reservation Systems
                    </h3>
                    <p className="lead">
                      Software solution that allows guests to book rooms,
                      services, and facilities online.
                    </p>
                    <ul className="card-text">
                      <li> User-Friendly Booking Interface</li>
                      <li> Real-Time Availability</li>
                      <li> Multi-Channel Booking</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={Activity}
                        alt="Tour and Activity Booking Software"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Tour and Activity Booking Software
                    </h3>
                    <p className="lead">
                      Facilitate the reservation and management of tours,
                      excursions, and activities in the travel and tourism
                      industry.
                    </p>
                    <ul className="card-text">
                      <li>Detailed Listings</li>
                      <li>Availability Calendar for Operators</li>
                      <li>Reservation Confirmation and Notifications</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img src={Event} alt="Event Management Software"></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Event Management Software
                    </h3>
                    <p className="lead">
                      Comprehensive solution designed to streamline and simplify
                      the planning, organization, and execution of events.
                    </p>
                    <ul className="card-text">
                      <li>Event Registration and Ticketing</li>
                      <li>Budget and Expense Tracking </li>
                      <li>Ticket Scanning and Access Control</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HospitalityAndTourism;
