import React from "react";
import Home from "../Home";
import WebImg from "../../img/web-development.webp";
import WebOptimization from "../../img/icons/web-optimization.png";
import WebDesign from "../../img/icons/web-design.png";
import PWA from "../../img/icons/pwa.png";
import AnalyticsAndInsights from "../../img/icons/analytics-and-insights.png";
import {Helmet} from "react-helmet";
const WebDevelopment = () => {
  return (
    <div className="bg-black text-light">
      <Helmet>
        <title>Web application for your business by Softaim Technologies</title>

        <meta
          name="description"
          content="Softaim Technologies - Responsive and mobile friendly websites, native app like experience, back-end development, APIs integration, offline access and push notifications and more."
        />
        <meta
          name="keywords"
          content="softaim technologies,web design, website for my business, payment application, mobile application, web application, website development, web development, online selling, product listing online, sell online, hostpital management, clinic website, online appointment scheduling"
        ></meta>
      </Helmet>
      <Home
        data={{
          heading: "Web development services by Softaim",
          description:
            "Responsive and mobile friendly websites or web applications for your business",
          image: WebImg,
          readMoreButton: false,
          // bgBlack: true,
        }}
      />
      <section
        id="services"
        className="container-fluid pb-5 p-100 text-light bg-black"
      >
        <div className="container">
          <div className="row g-4 align-items-end pb-4">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
              <h2 className="display-5 mb-4 text-center">
                Your online presence in today's digital world
              </h2>
              <h5 className="lead text-center">
                Provide several significant benefits to your businesses,
                regardless of their size or industry
              </h5>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={WebDesign}
                        alt="Web design and development"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Web design and development
                    </h3>

                    <ul className="card-text">
                      <li>Responsive and mobile friendly design</li>
                      <li>Back-end development</li>
                      <li>APIs integration</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={WebOptimization}
                        alt="Performance optimization"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Performance optimization
                    </h3>

                    <ul className="card-text">
                      <li> Fast loading time</li>
                      <li> Good user experience</li>
                      <li> Optimized images</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img src={PWA} alt="Progressive Web Apps"></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Progressive Web Apps (PWAs)
                    </h3>

                    <ul className="card-text">
                      <li> Native app like experience</li>
                      <li> Offline access and push notifications</li>
                      <li> Installation prompts</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={AnalyticsAndInsights}
                        alt="Analytics and Insights"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Analytics and Insights
                    </h3>

                    <ul className="card-text">
                      <li> Tracking website traffic</li>
                      <li> Customer behaviour data</li>
                      <li> Informed and data-driven decision</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WebDevelopment;
