import React from "react";
import Home from "../Home";
import FactoryAutomationImg from "../../img/factory-automation-full.webp";
import Vision from "../../img/icons/vision-system.png";
import Connected from "../../img/icons/connected-sensors.png";

import ProcessControl from "../../img/icons/process-control.png";

import DataAnalysis from "../../img/icons/analytics-and-insights.png";
import {Helmet} from "react-helmet";

const FactoryAutomation = () => {
  return (
    <div className="bg-black text-light">
      <Helmet>
        <title>Factory automation softwares by Softaim Technologies</title>‍
        <meta
          name="description"
          content="Softaim Technologies - Control and monitor various machines, devices, and processes in a factory. Integration of Sensors, Machine vision systems, Process control software and more. "
        />
        <meta
          name="keywords"
          content="softaim technologies,optical character recognition, automation software solutions, machine vision systems, sensor communication, collect data from sensor"
        ></meta>
      </Helmet>
      <Home
        data={{
          heading: "Factory Automation",
          description:
            "Control and monitor various machines, devices, and processes in a factory",
          image: FactoryAutomationImg,
          readMoreButton: false,
          //   bgBlack: true,
        }}
      />
      <section
        id="services"
        className="container-fluid pb-5 p-100 text-light bg-black"
      >
        <div className="container">
          <div className="row g-4 align-items-end pb-4 mt-4">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
              <h2 className="display-5 mb-4 text-center">Factory automation</h2>
              <h5 className="lead text-center">
                Enhancing the efficiency, accuracy, and control of manufacturing
                and production processes
              </h5>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img src={Vision} alt="machine vision systems"></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Machine vision systems
                    </h3>

                    <ul className="card-text">
                      <li>Image Processing and Feature Extraction</li>
                      <li>Optical Character Recognition </li>
                      <li>Dimensional Measurement</li>
                      <li>3D Vision</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img src={Connected} alt="connected sensors"></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Sensor communication
                    </h3>

                    <ul className="card-text">
                      <li> Photoelectric sensors</li>
                      <li> Vision and positioning systems</li>
                      <li> Ultrasonic sensors</li>
                      <li> Identification systems</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={ProcessControl}
                        alt="process control software"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Process control software
                    </h3>

                    <ul className="card-text">
                      <li>
                        Real-time monitoring and visualization of industrial
                        process
                      </li>
                      <li>Collect data from various sensors and instruments</li>
                      <li>
                        Control algorithms to regulate and manipulate process
                        variables
                      </li>
                      <li>Log and store historical data </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.45s">
              <div className="rounded">
                <div className="card bg-black text-light p-3 border rounded-3 service-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      <img
                        src={DataAnalysis}
                        alt="data integration and analysis"
                      ></img>
                    </h5>

                    <h3 className="card-title mb-3 pt-3">
                      Data Integration and Analysis
                    </h3>

                    <ul className="card-text">
                      <li>Analyze large amount of automation data</li>
                      <li>
                        Improve the efficiency of the manufacturing process
                      </li>
                      <li>Identify areas for optimization </li>
                      <li>Predict maintenance needs</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FactoryAutomation;
